<template>

      <div>
        <div  v-if="specific && specific.length !== 0" class="mt-5" >

          <div class="card_container" >
            <div class="card_header" >
              <font>นักศึกษานิเทศเฉพาะ(นิเทศเอก)</font>
            </div>
            <v-row>
              <v-col v-for="result in specific" :key="result.id" xl="3" lg="3" md="6" xs="12" cols="12" >
                <StudentCard
                    v-wow="{ 'animation-name': 'backInLeft','animation-duration': '1.7s'}"
                    :callback="()=> GotoAssessment('specific',result)"
                    :callback_asm="()=> GotoDetail(result.id)"
                    :data="result"
                    types="specific"
                />
              </v-col>
            </v-row>

          </div>
        </div>

        <div  v-if="general && general.length !== 0" class="mt-2" >

          <div class="card_container" >
            <div class="card_header" >
              <font>นักศึกษานิเทศทั่วไป</font>
            </div>
            <v-row>
              <v-col v-for="result in general" :key="result.id" xl="3" lg="3" md="6" xs="12" cols="12" >
                <StudentCard
                    v-wow="{ 'animation-name': 'backInRight','animation-duration': '1.7s'}"
                    :callback="()=> GotoAssessment('general',result)"
                    :callback_asm="()=> GotoDetail(result.id)"
                    :data="result"
                    types="general"
                />
              </v-col>
            </v-row>

          </div>
        </div>


        <div  v-if="teacher && teacher.length !== 0" class="mt-2" >

          <div class="card_container" >
            <div class="card_header" >
              <font>ข้อมูลนักศึกษาที่ดูแล</font>
            </div>
            <v-row>
              <v-col v-for="result in teacher" :key="result.id" xl="3" lg="3" md="6" xs="12" cols="12" >
                <StudentCard
                    v-wow="{ 'animation-name': 'backInLeft','animation-duration': '1.7s'}"
                    :callback="()=> GotoAssessment('teacher',result)"
                    :callback_asm="()=> GotoDetail(result.id)"
                    :data="result"
                    types="teacher"
                />
              </v-col>
            </v-row>

          </div>
        </div>


        <div  v-if="manager && manager.length !== 0" class="mt-2" >

          <div class="card_container" >
            <div class="card_header" >
              <font>ข้อมูลนักศึกษาภายในโรงเรียน</font>
            </div>
            <v-row>
              <v-col v-for="result in manager" :key="result.id" xl="3" lg="3" md="6" xs="12" cols="12" >
                <StudentCard
                    v-wow="{ 'animation-name': 'backInLeft','animation-duration': '1.7s'}"
                    :callback="()=> GotoAssessment('manager',result)"
                    :callback_asm="()=> GotoDetail(result.id)"
                    :data="result"
                    types="manager"
                />
              </v-col>
            </v-row>

          </div>
        </div>

      </div>


</template>
<script>
// @ is an alias to /src

import { getStudent} from "../lib/controller";
import {ResponseData} from "../lib/utility";
import StudentCard from "../components/StudentCard";

export default {
  name: 'Assessment',
  components:{
    StudentCard
  },
  data: () => ({
    is_student:true,
    users:JSON.parse(localStorage.getItem('user')),
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    specific:[],
    general:[],
    teacher:[],
    manager:[],
  }),
  methods:{
    async GetData() {

      this.is_loading = true;
      console.log(this)

      let params = {
        type:this.users.type,
        id: this.users.type == 'manager' ? this.users.school_id : this.users.id,
      }

      await getStudent(params, (response) => {

        const {status, data} = ResponseData(response)

        switch (status) {
          case 200:

              this.specific = data.specific_lecturer;
              this.general = data.general_lecturer;
              this.teacher = data.teacher;
              this.manager = data.manager;

            break;
          default:

            this.is_loading = false;
            break;
        }

      });
    },
    GotoAssessment(type,res){
      //console.log(res)
      this.$router.push({ path: "rate/"+type+"/"+res.id });
    },
    GotoDetail(id){
      //console.log(res)
      this.$router.push({ path: "detail/"+id });
    },
  },
  mounted() {
    this.GetData();
  }
}
</script>
