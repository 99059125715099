<template>

  <div>


    <v-app id="inspire">


      <nav v-if="auth" class="navbar" :class="{ 'sticky': isSticky, 'show': isMenuOpen }" >
        <div class="content">
          <div class="logo">
            <img v-wow="{ 'animation-name': 'backInLeft','animation-duration': '1.7s'}" src="./assets/image/logo.png" />
          </div>
          <ul class="menu-list">
            <div class="icon cancel-btn">
              <i class="fas fa-times" @click="closeMenu" :class="{ 'hide': !isMenuOpen }" ></i>
            </div>
            <li v-wow="{ 'animation-name': 'backInRight','animation-duration': '1.4s'}" >
              <router-link  to="/">
              <a @click="closeMenu" >หน้าหลัก</a>
              </router-link>
            </li>
            <li v-wow="{ 'animation-name': 'backInRight','animation-duration': '1.5s'}"  v-if="user.type == 'student' " >
              <router-link  to="/information">
              <a @click="closeMenu" >ข้อมูลการฝึกปฏิบัติงาน</a>
              </router-link>
            </li>
            <li v-wow="{ 'animation-name': 'backInRight','animation-duration': '1.6s'}"  v-if="user.type != 'student' "  >
              <router-link  to="/assessment">
              <a @click="closeMenu"  >
              การประเมิน</a>
              </router-link>
            </li>
            <li v-wow="{ 'animation-name': 'backInRight','animation-duration': '1.7s'}" ><a @click="Logout()" >ออกจากระบบ</a></li>
          </ul>
          <div class="icon menu-btn">
            <i  class="fas fa-bars"    @click="openMenu" :class="{ 'hide': isMenuOpen }" ></i>
          </div>
        </div>
      </nav>

      <v-main class="main_content" >
        <v-container  >
        <router-view/>
        </v-container>
      </v-main>
      <v-footer v-if="auth && this.$route.name.search('Print') === -1 && this.$route.name.search('Show') === -1"
                class="footer_custom" app>
        <div align="center">
          COPYRIGHT © 2023 -
        </div>
      </v-footer>
    </v-app>
  </div>

</template>

<script>

import {baseUrl} from "./const/api";


export default {
  name: 'App',

  components: {},
  data() {
    return {
      isMenuOpen: false,
      isSticky: false,
      version_system: "v 1.2.0",
      version_api: "0",
      version_service: "0",
      baseUrl: baseUrl,
      auth: localStorage.getItem('user') ? true : false,
      user: JSON.parse(localStorage.getItem('user')),
      items: [
        {title: 'Home', icon: 'mdi-home-city'},
        {title: 'My Account', icon: 'mdi-account'},
        {title: 'Users', icon: 'mdi-account-group-outline'},
      ],
      mini: true,
      drawer: null,
      is_loading: false
    }
  },
  methods: {
    Logout() {
      localStorage.removeItem('user');
      window.location = "/login"
    },
    CheckLogin() {
      console.log(this.$router.currentRoute.path)
      const UserData = localStorage.getItem('user');
      if (!UserData && this.$router.currentRoute.path !== "/login" && this.$route.name.search('Print') === -1 && this.$route.name.search('Show') === -1) {
        window.location = "/login"
      } else {

        //console.log("Nologin")
      }
    },

    openMenu() {
      this.isMenuOpen = true;
      this.$el.querySelector('.body').classList.add('disabled');
    },
    closeMenu() {
      this.isMenuOpen = false;
      this.$el.querySelector('.body').classList.remove('disabled');
    },
    onScroll() {
      this.isSticky = window.scrollY > 20;
    },

  },
  mounted() {
    this.CheckLogin()
    window.addEventListener('scroll', this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
  created() {
    //console.log(process.env)
  }
};




</script>
<style>
.footer_custom {
  justify-content: center;
}

.v-toolbar__content {
  display: flex;
  height: 64px;
  width: 100%;
  justify-content: space-between;
}

.version_show {
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.v-application .blue.lighten-5 {
  width: 300px !important;
}

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px !important;
}

.v-application ul, .v-application ol {
  padding-left: 55px !important;
}

.version_check {
  font-size: 0.9rem;
  padding: 1rem;
  color: #b3a6a6;
}
</style>
