<template>
      <v-row class="mt-5" align-content="center" justify="center"  >

        <v-col v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '1.3s'}" xl="4" lg="4" md="6" xs="12" cols="12" >
          <div class="card_container">
            <div align="center" class="profile_container" >
            <img  class="img-profile" :src="baseUrl+'/'+JSON.parse(users_.profile).path_thumb"><br>
              <div class="list_description" >
                <font><b>ชื่อ:</b> {{ users_.first_name}} {{ users_.last_name}} <br></font>
                <font v-if="users_.type == 'student' " ><b>รหัสนักศึกษา:</b> {{ users_.student_code }} <br></font>
                <font v-if="users_.type == 'student' || users_.type == 'lecturer' " ><b>สาขา:</b>{{ users_.branch }}<br></font>
                <font v-if="users_.type == 'student' " ><b>ห้อง:</b>-<br></font>
                <font v-if="users_.type == 'teacher' || users_.type == 'manager' " ><b>ตำแหน่ง:</b>{{ users_.position == "teacher"  ?  "ครู" : "ผู้บริหารสถานศึกษา" }}<br></font>
                <font v-if="users_.type == 'teacher' || users_.type == 'manager' " ><b>โรงเรียน:</b>{{ users_.school }}<br></font>
              </div>

            </div>
          </div>



        </v-col>

        <v-col v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '1.7s'}" v-if="users_.type == 'student' " xl="8" lg="8" md="6" xs="12" cols="12" >
          <div class="card_container">
            <font class="header_title" >ข้อมูลทั่วไป</font><hr class="horizontal_line" ><br>
            <div class="list_description" >
              <v-row>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>อาจารย์นิเทศเอก:</b> {{ users_.relationwork.specific && users_.relationwork.specific.first_name }} {{ users_.relationwork.specific && users_.relationwork.specific.last_name }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>อาจารย์นิเทศทั่วไป:</b> {{ users_.relationwork.general && users_.relationwork.general.first_name }} {{ users_.relationwork.general && users_.relationwork.general.last_name }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>โรงเรียนฝึกสอน:</b> {{ users_.relationwork.school && users_.relationwork.school.name }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>สังกัด:</b> {{ users_.relationwork.school && users_.relationwork.school.affiliation }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>รายวิชาที่สอน ภาคเรียนที่ 1:</b> {{ users_.relationwork.course_1 }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>ระดับชั้น:</b> {{ users_.relationwork.class_name_1 }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>รายวิชาที่สอน ภาคเรียนที่ 2:</b> {{ users_.relationwork.course_2 }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>ระดับชั้น:</b> {{ users_.relationwork.class_name_2 }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>ผู้บริหารสถานศึกษา:</b> {{ users_.relationwork.manager && users_.relationwork.manager.first_name }} {{ users_.relationwork.manager && users_.relationwork.manager.last_name }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>ครูพี่เลี้ยง :</b> {{ users_.relationwork.teacher && users_.relationwork.teacher.first_name }} {{ users_.relationwork.teacher && users_.relationwork.teacher.last_name }} </font></v-col>
              </v-row>
            </div><br>
          </div>

          <v-row class="mt-2" >
            <v-col xl="6" lg="6" md="6" xs="12" cols="12" >

              <div class="card_container">
                <font class="header_title" >อาจารย์นิเทศเอก</font><hr class="horizontal_line" ><br>
              <PeopleCard
                :data="users_.relationwork.specific"
                types="specific"
                :callback="()=> GotoAssessment('specific',users_.relationwork.specific)"
              />
              </div>
            </v-col>

            <v-col xl="6" lg="6" md="6" xs="12" cols="12" >

              <div class="card_container">
                <font class="header_title" >อาจารย์นิเทศทั่วไป</font><hr class="horizontal_line" ><br>
                <PeopleCard
                    :data="users_.relationwork.general"
                    types="general"
                    :callback="()=> GotoAssessment('general',users_.relationwork.general)"
                />
              </div>
            </v-col>

            <v-col xl="6" lg="6" md="6" xs="12" cols="12" >

              <div class="card_container">
                <font class="header_title" >ผู้บริหารสถานศึกษา</font><hr class="horizontal_line" ><br>
                <PeopleCard
                    :data="users_.relationwork.manager"
                    types="manager"
                    :callback="()=> GotoAssessment('manager',users_.relationwork.manager)"
                />
              </div>
            </v-col>

            <v-col xl="6" lg="6" md="6" xs="12" cols="12" >

              <div class="card_container">
                <font class="header_title" >ครูพี่เลี้ยง</font><hr class="horizontal_line" ><br>
                <PeopleCard
                    :data="users_.relationwork.teacher"
                    types="teacher"
                    :callback="()=> GotoAssessment('teacher',users_.relationwork.teacher)"
                />
              </div>
            </v-col>

          </v-row>

        </v-col>




      </v-row>
</template>
<script>
// @ is an alias to /src

import {baseUrlMain} from "../const/api";
import PeopleCard from "../components/PeopleCard";
import {getUser} from "../lib/controller";
import {ResponseData} from "../lib/utility";

export default {
  name: 'Home',
  components:{
    PeopleCard
  },
  data: () => ({
    is_student:true,
    users:JSON.parse(localStorage.getItem('user')),
    users_:JSON.parse(localStorage.getItem('user')),
    baseUrl:baseUrlMain,
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
  }),
  methods:{
    async GetData() {

      this.is_loading = true;
      console.log(this)

      let params = {
        type:this.users_.type,
        id: this.users_.id,
      }

      await getUser(params, (response) => {

        const {status, data} = ResponseData(response)

        switch (status) {
          case 200:

            this.users__ = data;

            break;
          default:

            this.is_loading = false;
            break;
        }

      });
    },
    GotoAssessment(type,res){
      //console.log(res)
      this.$router.push({ path: "rate_view/"+type+"/"+res.id+"/"+this.users_.id });
    },
  },
  async mounted() {
   await this.GetData();
  }
}
</script>
