import {API, instance} from "../const/api";




export async function getDepartment(Calback){

    await instance.get(API.Department)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function getData(params,Calback){

    await instance.get(API.Getdata,{
        params
    })
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function postAAR1(formData,Calback){

    await instance.post(API.AAR1,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function postAAR2(formData,Calback){

    await instance.post(API.AAR2,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function postAAR3(formData,Calback){

    await instance.post(API.AAR3,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function postPLC(formData,Calback){

    await instance.post(API.PLC,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function postPLC2(formData,Calback){

    await instance.post(API.PLC2,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function postPlan(formData,Calback){

    await instance.post(API.Plan,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function postPlanHistory(formData,Calback){

    await instance.post(API.PlanHistory,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function postPlanDetail(formData,Calback){

    await instance.post(API.PlanDetail,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function postPlanRequirement(formData,Calback){

    await instance.post(API.PlanRequirement,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function postProfile(formData,Calback){

    await instance.post(API.Profile,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function postCerticate(formData,Calback){

    await instance.post(API.Certificate,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function postCourse(formData,Calback){

    await instance.post(API.Course,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function postWork(formData,Calback){

    await instance.post(API.Works,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}
export async function postWorkCommunity(formData,Calback){

    await instance.post(API.WorksCommunity,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function deletePlanDetail(id,Calback){

    await instance.delete(API.DeletePlanDetail+"/"+id)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function deletePlanHistory(id,Calback){

    await instance.delete(API.DeletePlanHistory+"/"+id)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}



export async function deleteCer(id,Calback){

    await instance.delete(API.DeleteCer+"/"+id)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}


export async function deleteCourse(id,Calback){

    await instance.delete(API.DeleteCourse+"/"+id)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function deleteWork(id,Calback){

    await instance.delete(API.DeleteWork+"/"+id)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function deleteWorkCommu(id,Calback){

    await instance.delete(API.DeleteWorkCommu+"/"+id)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function postAssessment(formData,Calback){

    await instance.post(API.Assessment,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}



export async function getStudent(params,Calback){

    await instance.get(API.GetStudent,{params})
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function postListLink(formData,Calback){

    await instance.post(API.ListLink,formData)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}



export async function getListLink(id,Calback){

    await instance.get(API.ListLink+"/"+id)
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}

export async function getUser(params,Calback){

    await instance.get(API.GetUsers,{params})
        .then(function (response) {
            Calback(response)
        })
        .catch(function (response) {
            Calback(response.response)
        });
}
